import React, { useCallback, useState } from "react";

import { useContext, useEffect } from "react";
// @types
//
import ChatMessageItem from "./ChatMessageItem";
import { ChatContext } from "../context";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useWidgetSettingsContext } from "../settings";
import Scrollbar from "../components/scrollbar";
import { mockResponse } from "../mockData";
import { DEFAULT_LOGO } from "../lib/firestore";
import ChatConversationStarter from "./ChatConversationStarter";
import { useDebounce } from "../hooks/use-debounce";
import useMessagesScroll from "../hooks/use-message-scroll";

export default function ChatMessageList() {
  const settings = useWidgetSettingsContext();
  const conversationStarters = settings.conversationStarters;
  const assistant = settings.assistant;

  const { messages, status, errorMessage, isCreatingChat, isFunctionCalling } =
    useContext(ChatContext);

  const filteredconversationStarters = conversationStarters
    ? conversationStarters.filter((starter) => starter.text.trim().length)
    : [];

  const debouncedMessagesLength = useDebounce(messages, 200);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(true);

  const { messagesEndRef } = useMessagesScroll(
    debouncedMessagesLength,
    !isAutoScrollEnabled
  );

  const handleScroll = useCallback(() => {
    if (!messagesEndRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current;

    const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 120;

    if (isAtBottom) {
      setIsAutoScrollEnabled(true);
    } else {
      setIsAutoScrollEnabled(false);
    }
  }, []);

  useEffect(() => {
    const messagesContainer = messagesEndRef.current;

    if (messagesContainer) {
      messagesContainer.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }
    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, messagesEndRef?.current]);

  useEffect(() => {
    if (messagesEndRef.current && isAutoScrollEnabled && status === "typing") {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [messages, isAutoScrollEnabled, status]);

  return (
    <Scrollbar
      ref={messagesEndRef}
      sx={{
        ...(!messages?.length
          ? {
              display: "flex",
              flexDirection: "column",
              flex: "1 1 auto",
              "& .simplebar-content": {
                display: "flex",
                flexDirection: "column",
                height: 1,
              },
            }
          : {
              height: 1,
              mb: { xs: 2, sm: 0 },
            }),
      }}
    >
      <Stack
        sx={{
          height: 1,
          p: 2,
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            mb: 4,
          }}
        >
          <Avatar
            alt={settings?.name}
            src={(settings.assistantAvatar as any)?.preview || DEFAULT_LOGO}
            sx={{ width: 80, height: 80 }}
          />
          <Typography
            variant="subtitle1"
            textAlign="center"
            sx={{
              mt: 1,
            }}
          >
            {assistant?.name || assistant?.openai?.name || settings?.name}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            textAlign="center"
            sx={{
              mt: 0.5,
            }}
          >
            {assistant?.description ||
              assistant?.openai?.description ||
              settings?.description}
          </Typography>
        </Stack>
        {(messages?.length === 0 ||
          messages[messages.length - 1]?.content === mockResponse) && (
          <Stack
            sx={{
              height: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns:
                  filteredconversationStarters?.length === 1
                    ? "0.5fr"
                    : { xs: "1fr", sm: "1fr 1fr" },
                pb: {
                  xs: 2,
                  md: filteredconversationStarters?.length > 1 ? 2 : 0,
                },
                alignItems: "stretch",
                width: 1,
                gap: 1,
                justifyContent:
                  filteredconversationStarters?.length === 1
                    ? "center"
                    : "initial",
                flexWrap: "wrap",
              }}
            >
              {filteredconversationStarters?.map((starter, index) => (
                <ChatConversationStarter starter={starter} key={index} />
              ))}
            </Box>
          </Stack>
        )}
        {!isCreatingChat.value &&
          messages?.map((message, index) => (
            <ChatMessageItem
              key={index}
              message={message}
              isFunctionCalling={isFunctionCalling}
            />
          ))}

        {status === "typing" &&
          messages[messages?.length - 1]?.role === "user" && (
            <ChatMessageItem
              loadingMessage
              message={{
                role: "assistant",
                content: "Typing...",
                createdAt: Timestamp.now(),
              }}
            />
          )}
        {status === "error" &&
        messages?.length &&
        messages[messages?.length - 1]?.role != "assistant" ? (
          <ChatMessageItem
            error
            message={{
              role: "assistant",
              content: errorMessage,
              createdAt: Timestamp.now(),
            }}
          />
        ) : null}
      </Stack>
    </Scrollbar>
  );
}
