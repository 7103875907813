import {
  Timestamp,
  arrayUnion,
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getResponse, getResponsePRO, widgetDB } from "./firebase";
import { WidgetDoc } from "./types";
import { ChatDoc } from "../types";
import { setSessionStorage } from "../hooks/use-session-storage";
import uuidv4 from "../utils/uuidv4";

//-------------------------------------------------------------------

export const DEFAULT_LOGO =
  "https://firebasestorage.googleapis.com/v0/b/openai-widget-dev.appspot.com/o/widgets%2Fdefault-icons%2Flogo_agencii.svg?alt=media&token=f96284f9-f859-45b0-acc3-e283de0e1e73";

//-------------------------------------------------------------------

export const getWidgetById = async (widgetId: string): Promise<WidgetDoc> => {
  const docRef = doc(widgetDB, `widgets/${widgetId}`);
  const docSnap = await getDoc(docRef);

  return docSnap.data() as WidgetDoc;
};

export const createChatDoc = async (
  widgetId: string,
  ownerUid: string
): Promise<string> => {
  const newDocId = doc(collection(widgetDB, `widgets/${widgetId}/chats`)).id;

  const newChat: ChatDoc = {
    chatId: newDocId,
    createdAt: Timestamp.now(),
    messages: [],
    createdBy: "user",
    widgetId,
    ownerUid,
    integrationOwnerUid: ownerUid,
  };

  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, newDocId);

  await setDoc(docRef, newChat);

  return newDocId;
};

export const getMessage = async (
  widgetId: string,
  chatId: string,
  assistantId: string,
  message: string,
  messages: any[],
  isPaid?: boolean
) => {
  try {
    const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

    const newId = uuidv4();
    const clientTimeStamp = Timestamp.now();

    await updateDoc(docRef, {
      messages: arrayUnion({
        type: "text",
        role: "user",
        content: message,
        createdAt: clientTimeStamp,
        id: newId,
      }),
      lastUpdated: clientTimeStamp,
      lastMessageTimestamp: {
        id: newId,
        timestamp: serverTimestamp(),
      },
    });

    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();

      // Get the processed serverTimestamp for the last message
      const serverTimestampProcessed = data.lastMessageTimestamp?.timestamp;

      if (serverTimestampProcessed instanceof Timestamp) {
        // Use the server-provided timestamp to update the message's createdAt field
        const updatedMessages = (data.messages || []).map((msg: any) => {
          if (msg.id === newId) {
            return {
              ...msg,
              createdAt: serverTimestampProcessed, // Use the accurate server timestamp
            };
          }
          return msg;
        });

        // Step 3: Update the messages array with the accurate server-side createdAt timestamp
        await updateDoc(docRef, {
          messages: updatedMessages,
        });
      }
    }
  } catch (error: any) {
    if (error?.code === "functions/deadline-exceeded") {
      if (isPaid) {
        throw new Error("Sorry, your request timed out.");
      } else {
        throw new Error("Please upgrade to PRO to increase timeout.");
      }
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("timeout")) {
      throw new Error("Timeout. Please try again.");
    }
    if (JSON.stringify(error).toLocaleLowerCase().includes("internal")) {
      throw new Error("Something went wrong. Please try again.");
    }
    throw error;
  }
};

export const clearAllConversation = async (
  widgetId: string,
  chatId: string
) => {
  const docRef = doc(widgetDB, `widgets/${widgetId}/chats`, chatId);

  setSessionStorage(`user-widget-id-${widgetId}`, "");
};
