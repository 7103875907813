// @mui
import {
  Typography,
  Stack,
  Box,
  Link,
  styled,
  alpha,
  useTheme,
  Fade,
  IconButton,
} from "@mui/material";
// @types
import React from "react";
import { Message } from "../types";
// components
import TypingIndicator from "../components/TypingIndicator";
import { bgGradient } from "../theme/css";
import { useWidgetSettingsContext } from "../settings";
import Markdown from "../components/markdown";
import { useBoolean } from "../hooks/use-boolean";
import { useResponsive } from "../hooks/use-responsive";
import Iconify from "../components/iconify";
import FunctionCallItem from "./FunctionCallItem";

// ----------------------------------------------------------------------

const MessageContainer = styled(Box)(({ theme }) => ({
  padding: "8px 10px",
  width: "auto",
  maxWidth: "100%",
  borderRadius: 8,
  overflow: "hidden",
  typography: theme.typography.body2,
  backgroundColor: theme.palette.background.neutral,
  color: theme.palette.grey[900],
}));

type Props = {
  message: Message;
  loadingMessage?: boolean;
  error?: boolean;
  isFunctionCalling?: string[];
};

export default function ChatMessageItem({
  message,
  loadingMessage = false,
  error = false,
  isFunctionCalling,
}: Props) {
  const theme = useTheme();
  const settings = useWidgetSettingsContext();

  const sender = message.role;
  const isNotMobile = useResponsive("up", 380);
  const showCopy = useBoolean(false);
  const botSenderName =
    message.assistantData?.name ||
    settings.assistant?.name ||
    settings?.assistant?.openai?.name ||
    settings.name;

  const senderDetails =
    sender === "user"
      ? {
          type: "me",
        }
      : {
          name: botSenderName,
        };

  const currentUser =
    loadingMessage || error ? false : senderDetails.type === "me";

  return (
    <Stack
      direction="row"
      justifyContent={currentUser ? "flex-end" : "unset"}
      sx={{
        mb: isNotMobile ? 2 : 3,
        width: 1,
        "&:last-child": {
          mb: 0,
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: 1,
          transition: "all .2s linear",
          "&:hover": {
            "& .message-actions": {
              opacity: 1,
            },
            "& .copy-button": {
              opacity: 1,
              width: "initial",
              height: "initial",
            },
          },
        }}
        spacing={1}
        alignItems={sender === "assistant" ? "flex-start" : "flex-end"}
      >
        <Typography
          noWrap
          variant="caption"
          fontWeight={500}
          sx={{
            color: "text.secondary",
            ...(!currentUser && {
              mr: "auto",
            }),
          }}
        >
          {currentUser ? "You" : botSenderName}
        </Typography>
        <Stack gap={1} position="relative" maxWidth={1}>
          <MessageContainer
            sx={{
              boxSizing: "border-box",
              backgroundColor: "background.default",
              color: "text.primary",
              borderRadius: 2,
              overflowWrap: "break-word",
              wordBreak: "break-word",
              ...(currentUser && {
                ...bgGradient({
                  direction: "135deg",
                  startColor: alpha(theme.palette.primary.light, 0.2),
                  endColor: alpha(theme.palette.primary.main, 0.2),
                }),
                position: "relative",
                color:
                  theme.palette.mode === "dark"
                    ? "primary.lighter"
                    : "primary.darker",
                backgroundColor: "common.white",
              }),
              ...((loadingMessage || !currentUser) && {
                alignSelf: "flex-start",
              }),
            }}
          >
            {loadingMessage ? (
              <Box marginLeft={0.5}>
                <TypingIndicator />
              </Box>
            ) : error ? (
              <Box marginLeft={0.5}>
                <Typography variant="body2" color="error">
                  {message.content}
                </Typography>
              </Box>
            ) : (
              <>
                {message.functions
                  ? Object.entries(message.functions).map(
                      ([id, functionCall]) => (
                        <FunctionCallItem
                          mode={"web-apps"}
                          key={id}
                          functionCall={functionCall}
                          isFunctionCalling={isFunctionCalling?.includes(
                            functionCall.id
                          )}
                        />
                      )
                    )
                  : null}

                <Markdown>
                  {/* <FormatMarkdownLinks text={message.content} /> */}
                  {message.content}
                </Markdown>
              </>

              // message.content
            )}
          </MessageContainer>
          {message.role === "assistant" && isNotMobile && !loadingMessage && (
            <Box
              className="copy-button"
              sx={{
                opacity: 0,
                transition: "all 250ms",
              }}
            >
              <Fade in={showCopy.value}>
                <Typography
                  variant="caption"
                  sx={{
                    position: "absolute",
                    bottom: 7,
                    left: 40,
                    color: "text.disabled",
                  }}
                >
                  Copied!
                </Typography>
              </Fade>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(message.content);
                  showCopy.onTrue();

                  setTimeout(() => {
                    showCopy.onFalse();
                  }, 2000);
                }}
              >
                <Iconify icon="tabler:copy" width={18} />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

/**
 * Renders a formatted text with links.
 * Line breaks in the input text are also preserved.
 */

function addBreaks(str: string) {
  return str.replace(/([/&=])/g, "$1\u00AD");
}

function FormatMarkdownLinks({ text }: { text: string }) {
  const parts = text.split(/(\[[^\]]+\]\([^)]+\)|\n)/g);

  return (
    <Typography variant="body2" fontWeight={500}>
      {parts.map((part, index) => {
        if (part.startsWith("[")) {
          const matches = /\[([^\]]+)\]\(([^)]+)\)/.exec(part);
          if (matches && matches.length >= 3) {
            return (
              <Link
                key={index}
                href={matches[2]}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  overflowWrap: "break-word",
                }}
              >
                {matches[1]}
              </Link>
            );
          }
        } else if (part === "\n") {
          return <br key={index} />;
        }
        return <React.Fragment key={index}>{addBreaks(part)}</React.Fragment>;
      })}
    </Typography>
  );
}
